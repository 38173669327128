/* eslint-disable no-shadow */
import NProgress from 'nprogress';

const MATCH_LOCALE = /^\/(..)($|\/)/;

const FLAGS = {
  NAME: 'JKROUTER',
  POPUP: 'POPUP',
  PAGE: 'PAGE',
};

const state = () => {
  const state = window.INITAL_ROUTER || {
    base: 'https://lpgmbh.ch/api',
    locales: ['de', 'en'],

    locale: 'de',
    layout: {},
    pages: {
      404: { content: [{ component: 'NotFound', data: { id: -404 } }] },
    },

    page: {},
    popup: null,
  };

  state.beta = ['localhost', 'beta.'].find((domain) => window.location.hostname.startsWith(domain))
    && !window.prerender;

  return state;
};

const actions = {
  /**
   * init router
   */
  async init({ state, commit, dispatch }, { uri, hydrate = false }) {
    // find locale from url

    const urlLocale = uri.match(MATCH_LOCALE)?.[1];
    if (urlLocale && state.locales.includes(urlLocale)) {
      await commit('setLocale', urlLocale);
    }
    if (uri.length <= 3) {
      // eslint-disable-next-line no-param-reassign
      uri = `/${state.locale}`;
    }

    // load everything
    if (!hydrate) {
      await Promise.all([dispatch('loadLayout'), dispatch('push', { uri })]);

      // set state
    } else {
      window.history.replaceState(
        {
          name: FLAGS.NAME,
          type: state.popup ? FLAGS.POPUP : FLAGS.PAGE,
        },
        undefined,
        uri,
      );
    }
  },

  /**
   * load new layout
   */
  async loadLayout({ state, commit }) {
    const response = await fetch(`${state.base}/${state.locale}/ajax/layout`);
    const json = await response.json();
    await commit('setLayout', json);
  },

  /**
   * load new page (shows progress bar)
   */
  async loadPage({ state, commit }, { uri }) {
    NProgress.start();
    const response = await fetch(`${state.base}${uri}`);
    const json = await response.json();
    await commit('addPage', { uri, data: json });
    NProgress.done();
  },

  /**
   * change page (doesn't edit history)
   */
  async navigate({ state, commit, dispatch }, { uri }) {
    if (!state.pages[uri]) await dispatch('loadPage', { uri });
    if (state.pages[uri].meta.status !== 200) {
      await commit('setCurrent', 404);
      return;
    }

    // if locale changes
    const urlLocale = uri.match(MATCH_LOCALE)?.[1];
    if (urlLocale && state.locales.includes(urlLocale) && urlLocale !== state.locale) {
      commit('setLocale', urlLocale);
      dispatch('loadLayout');
    }

    commit('setPopup', { uri: null });
    await commit('setCurrent', uri);
  },

  /**
   * set new popup state
   */
  async popup({ state, commit, dispatch }, { uri, action = 'pushState' }) {
    const specialURI = `${uri}?type=popup`;

    if (!state.pages[specialURI]) await dispatch('loadPage', { uri: specialURI });
    if (state.pages[specialURI].meta.status !== 200) {
      await commit('setCurrent', 404);
    }

    await commit('setPopup', { uri: specialURI });

    window.history[action](
      {
        name: FLAGS.NAME,
        type: FLAGS.POPUP,
      },
      undefined,
      uri,
    );
  },

  /**
   * jump to new state (with history update)
   */
  async push({ dispatch }, { uri, noscroll, action = 'pushState' }) {
    if (!noscroll) window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    await dispatch('navigate', { uri });

    const [path, hash] = uri.split('#');
    window.history[action](
      {
        name: FLAGS.NAME,
        type: FLAGS.PAGE,
      },
      undefined,
      path,
    );

    if (hash) {
      window.location.hash = hash;
    }
  },

  /**
   * jump to previous state (with history update)
   */
  async pop({ dispatch, commit, state }, { path, data }) {
    if (data?.name !== FLAGS.NAME) return;

    if (data && data.type === FLAGS.PAGE && state.popup) await commit('setPopup', { uri: null });
    else if (data && data.type === FLAGS.POPUP) await commit('setPopup', { uri: decodeURI(path) });
    else await dispatch('navigate', { uri: decodeURI(path) });
  },
};

const mutations = {
  setLayout(state, layout) {
    this.commit('router/setLocale', layout.locale);
    state.layout = layout;
  },

  addPage(state, { uri, data }) {
    state.pages[uri] = data;
  },

  setCurrent(state, uri) {
    state.page = state.pages[uri];
  },

  setPopup(state, { uri }) {
    state.popup = uri === null ? null : state.pages[`${uri}?type=popup`] || state.pages[uri];
  },

  setLocale(state, locale) {
    state.locale = locale;

    state.locales = state.locales.filter((l) => l !== locale);
    state.locales.unshift(locale);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
