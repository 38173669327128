<template>
  <square>
    <button class="icon-button" :class="{ disabled }">
      <slot />
    </button>
  </square>
</template>

<script>
export default {
  props: { disabled: { type: Boolean, default: false } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.icon-button {
  @apply text-primary;
  @apply rounded-full;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary);
  height: 100%;
  transition:
    color var(--speed-fast),
    background-color var(--speed-fast),
    border-color var(--speed-fast);

  @include responsive('width', px(26), px(45));
  @include responsive('height', px(26), px(45));

  &:hover {
    @apply bg-primary;
    @apply text-white;
  }

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &.disabled {
    @apply text-gray;
    border-color: var(--color-gray);
    pointer-events: none;
  }

  & > :deep(*) {
    width: 100%;
    height: 100%;
    max-width: 50%;
    max-height: 50%;
  }

  :deep(svg) path {
    stroke: currentColor;
  }
}
</style>
