<template>
  <template v-if="!fadeSwap">
    <span v-if="!src" />
    <span v-else-if="src.svg" :alt="src.alt" v-html="src.svg" />
    <img
      v-else
      ref="image"
      :alt="src.alt"
      :src="getSrc(src)"
      :srcset="getSrcSet(src)"
      :sizes="getSizes(src)"
      loading="lazy"
    >
  </template>

  <div v-else class="image-anchor" :class="{ alternate }">
    <span v-if="!newSrc" />
    <span
      v-else-if="newSrc?.svg"
      :alt="newSrc?.alt"
      v-html="newSrc?.svg"
    />
    <img
      v-else
      ref="image"
      :alt="newSrc.alt"
      :src="getSrc(newSrc)"
      :srcset="getSrcSet(newSrc)"
      :sizes="getSizes(newSrc)"
      loading="lazy"
    >

    <span v-if="!oldSrc" />
    <span
      v-else-if="oldSrc?.svg"
      :alt="oldSrc?.alt"
      v-html="oldSrc?.svg"
    />
    <img
      v-else
      ref="image"
      :alt="oldSrc?.alt"
      :src="getSrc(oldSrc)"
      :srcset="getSrcSet(oldSrc)"
      :sizes="getSizes(oldSrc)"
      loading="lazy"
    >
  </div>
</template>

<script>
import {
  inject, ref, toRefs, watch,
} from 'vue';

const breakpoints = {
  xl: '1900px',
  lg: '1500px',
  md: '920px',
  sm: '640px',
};

export default {
  props: {
    src: { type: Object, default: null },
    sizes: { type: Object, default: () => ({}) },
    fadeSwap: { type: Boolean, default: false },
  },
  setup(props) {
    const oldSrc = ref(null);
    const alternate = ref(false);
    const { src } = toRefs(props);
    const newSrc = ref(src.value);
    watch(src, (next, old) => {
      if (alternate.value) {
        oldSrc.value = old;
        newSrc.value = next;
      } else {
        oldSrc.value = next;
        newSrc.value = old;
      }
      alternate.value = !alternate.value;
    });
    const choose = (alt, a, b) => (!alt ? a : b);
    const tryCDN = inject('tryCDN');

    const getSizes = () => undefined;
    /*
      // Currently disabled
      let sizes = '';
      if (props.sizes) {
      // eslint-disable-next-line no-restricted-syntax
        for (const breakpoint in breakpoints) {
        // eslint-disable-next-line no-continue
          if (!props.sizes[breakpoint]) continue;
          sizes += `(min-width: ${breakpoints[breakpoint]}) ${props.sizes[breakpoint]}, `;
        }
        sizes += props.sizes.min ? props.sizes.min : '95vw';
      } else sizes = '95vw';
      return sizes;
      */

    const getSrcSet = (source) => undefined;
    // Currently disabled
    /*

      const srcset = [];
      for (let width = 100; width < 3000; width += Math.ceil(width * 0.16)) {
        srcset.push(`${tryCDN(source.large, width)} ${width}w`);
      }
      return srcset.join(', ');
      */

    const getSrc = (source) => tryCDN(source.original); // tryCDN(source.normal);

    return {
      alternate,
      oldSrc,
      newSrc,
      choose,
      getSizes,
      getSrcSet,
      getSrc,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-anchor {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    transition: opacity var(--speed-slow) ease;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img:first-child {
    opacity: 1;
  }

  img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.alternate {
    img:first-child {
      opacity: 0;
    }
    img:last-child {
      opacity: 1;
    }
  }
}
</style>
