<template>
  <Go
    :to="to"
    class="inline-block mt-4 button"
    :class="[type]"
  >
    <LongArrow class="arrow" />
    <span class="slot"><slot /></span>
  </Go>
</template>

<script>
import LongArrow from '@/assets/images/long_arrow.svg';

export default {
  components: { LongArrow },
  props: {
    to: { type: String, default: null },
    type: { type: String, default: 'right' },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-left: 2em;
  padding-right: 2.7em;

  .arrow {
    position: absolute;
    right: calc(100% - 1.2em);
    bottom: 0.2em;
    transition: right 0.25s ease-in-out;
    height: 1em;
  }

  .slot {
    display: inline-block;
    transition: transform 0.25s ease-in-out;
    transition-delay: 0.15s;
  }

  &:hover, .hover:hover & {
    .arrow {
      right: 1.2em;
      transition-delay: 0.2s;
    }
    .slot {
      transform: translateY(-22px);
      transition-delay: 0;
    }
  }
}

.button.left {
  padding-left: 2.7em;
  padding-right: 2em;

  .arrow {
    right: auto;
    transition: left 0.25s ease-in-out;
    left: calc(100% - 1.2em);
    transform: rotate(180deg);
  }

  &:hover, .hover:hover & {
    .arrow {
      right: auto;
      left: 1.2em;
      transition-delay: 0.2s;
    }
  }
}

.button.diogonal {
  .arrow {
    bottom: 0.5em;
    right: calc(100% - 0.8em);
    transform: rotate(-45deg);
    transform-origin: top right;
    transition: transform 0.25s ease-in-out;
  }
  .slot {
    transition-delay: 0s;
  }
  &:hover, .hover:hover & {
    .arrow {
      transform: rotate(-45deg) translateX(23px);
    }
    .slot {
      transform: translateX(8px);
    }
  }
}
</style>
