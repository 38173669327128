const colors = require('tailwindcss/colors');

module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
    ],
    options: {
      safelist: [
        // bg colors
        'bg-primary', 'bg-secondary', 'bg-active', 'bg-gray', 'bg-blue',
      ],
    },
  },

  theme: {

    colors: {
      primary: {
        DEFAULT: 'var(--color-primary)',
      },
      secondary: {
        DEFAULT: 'var(--color-secondary)',
      },
      active: {
        DEFAULT: 'var(--color-active)',
      },
      gray: {
        DEFAULT: 'var(--color-gray)',
      },
      blue: {
        DEFAULT: 'var(--color-blue)',
        teal: 'var(--color-blue-teal)',
      },
      orange: {
        DEFAULT: 'var(--color-orange)',
        lite: 'var(--color-orange-lite)',
      },
      white: colors.white,
      black: colors.black,
    },

    extend: {
      textColor: {
        blue: 'var(--color-blue)',
        'blue-teal': 'var(--color-blue-teal)',
        'orange-lite': 'var(--color-orange-lite)',
      },
      fontFamily: {
        sans: ['Frutiger LT Pro', 'sans-serif'],
        serif: ['Merriweather', 'serif'],
      },
      borderRadius: {
        small: '0.3125rem',
        big: '0.625rem',
      },
    },

    fontSize: {
      xs: '0.8125rem', // = 13px
      sssm: '0.9375rem', // = 15px
      ssm: '1rem', // = 16px
      sm: '1.0625rem', // = 17px
      m: '1.25rem', // = 20px
      base: '1.5rem', // = 24px
      lg: '2rem', // = 32px
      xl: '3.75rem', // 60px
    },

    container: {
      center: true,
    },

    screens: {
      sm: '640px',
      md: '920px',
      lg: '1200px',
      xl: '1640px',
    },

  },
  variants: {
    extend: {},
  },
  plugins: [],
};
