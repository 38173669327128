<template>
  <div class="progress-line">
    <icon-button
      class="prev"
      :disabled="currentPage === 1"
      @click="prev"
    >
      <arrow />
    </icon-button>
    <icon-button
      class="next"
      :disabled="currentPage === pages"
      @click="next"
    >
      <arrow />
    </icon-button>
    <progress-bar :start="start" :end="end" />
    <span class="pages">
      {{ currentPage }} / {{ pages }}
    </span>
  </div>
</template>

<script>
import Arrow from '@/assets/images/arrow.svg?inline';

export default {
  components: { Arrow },
  props: {
    start: { type: Number, default: 0 },
    end: { type: Number, default: 0.5 },
    currentPage: { type: Number, default: 1 },
    pages: { type: Number, default: 3 },
  },
  emits: ['prev', 'next'],
  setup(_, ctx) {
    return {
      prev() {
        ctx.emit('prev');
      },
      next() {
        ctx.emit('next');
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.progress-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prev, .next, .pages {
  flex: 1 0 auto;
}

.prev {
  transform: rotate(180deg);

  @include responsive('margin-right', px(10), px(12));
}

.next {
  @include responsive('margin-right', px(26), px(45));
}

.pages {
  @apply text-xs;
  @apply font-serif;
  @apply text-primary;

  @include responsive('line-height', px(14), px(26));
  @include responsive('margin-left', px(26), px(60));

  @screen md {
    @apply text-base;
  }
}
</style>
