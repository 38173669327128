<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Footer = defineAsyncComponent(() => import('@/components/structure/Footer.vue'));

const Header = defineAsyncComponent(() => import('@/components/parts/Header.vue'));
const LeistungenSlider = defineAsyncComponent(() => import('@/components/parts/LeistungenSlider.vue'));
const Leistungen = defineAsyncComponent(() => import('@/components/parts/Leistungen.vue'));
const WerSindWirTeaser = defineAsyncComponent(() => import('@/components/parts/WerSindWirTeaser.vue'));
const ReferenzTeaser = defineAsyncComponent(() => import('@/components/parts/ReferenzTeaser.vue'));
const Person = defineAsyncComponent(() => import('@/components/parts/Person.vue'));
const Feedback = defineAsyncComponent(() => import('@/components/parts/Feedback.vue'));
const Werte = defineAsyncComponent(() => import('@/components/parts/Werte.vue'));
const Referenzen = defineAsyncComponent(() => import('@/components/parts/Referenzen.vue'));
const Gallery = defineAsyncComponent(() => import('@/components/parts/Gallery.vue'));
const Text = defineAsyncComponent(() => import('@/components/parts/Text.vue'));
const Addressen = defineAsyncComponent(() => import('@/components/parts/Addressen.vue'));
const Kontakt = defineAsyncComponent(() => import('@/components/parts/Kontakt.vue'));
const Kontaktpersonen = defineAsyncComponent(() => import('@/components/parts/Kontaktpersonen.vue'));

export default {
  components: {

    NotFound,
    Footer,

    Header,
    LeistungenSlider,
    Leistungen,
    WerSindWirTeaser,
    ReferenzTeaser,
    Person,
    Feedback,
    Werte,
    Referenzen,
    Gallery,
    Text,
    Addressen,
    Kontakt,
    Kontaktpersonen,

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
