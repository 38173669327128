<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  box-sizing: content-box;
  width: 20px;

  &.active .bar {
    @apply bg-white;
  }
}

.bar {
  height: 2px;
  background-color: black;
  transition: all 0.5s;
  border-radius: 2px;
  margin-left: auto;

  &:nth-child(1) {
    width: 100%;
    margin-bottom: 5.5px;
    .active & {
      transform: translateY(8.5px) rotate(45deg) scaleX(1.2);
    }
  }

  &:nth-child(2) {
    width: 12px;
    transform: translateX(0);
    margin-bottom: 5.5px;
    .active & {
      opacity: 0;
      transform: translateX(20px) !important;
    }
  }

  &:nth-child(3) {
    width: 16px;
    .active & {
      transform: translateY(-8.5px) rotate(-45deg) scaleX(1.5) translateX(-2px);
    }
  }

}
</style>
