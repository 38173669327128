<template>
  <div
    ref="boxRef"
    class="box"
    :class="{
      'small-border': smallBorder,
      'force-aspect-ratio': forceAspectRatio,
      padding,
      background,
    }"
    :style="cssVars({ boxHeight, aspect })"
  >
    <div v-if="forceAspectRatio" class="content">
      <slot />
    </div>
    <slot v-else />
  </div>
</template>

<script>
import {
  onBeforeUnmount, onMounted, ref, toRefs, watch,
} from 'vue';
import { cssVars } from '@/utils/css';
import { useResizeObserver } from '@/composables/observer';

export default {
  props: {
    smallBorder: { type: Boolean, default: false },
    forceAspectRatio: { type: Boolean, default: false },
    aspect: { type: Number, default: 1 },
    syncHeight: { type: Boolean, default: false },
    padding: { type: Boolean, default: false },
    background: { type: Boolean, default: false },
  },
  setup(props) {
    const boxHeight = ref(undefined);
    const boxRef = ref(null);
    const { syncHeight } = toRefs(props);

    const { observe, unobserve } = useResizeObserver(boxRef, (entry) => {
      boxHeight.value = `${entry.borderBoxSize?.[0].blockSize}px`;
    });

    onMounted(() => {
      if (syncHeight.value) observe();
    });

    onBeforeUnmount(() => {
      if (syncHeight.value) unobserve();
    });

    watch(syncHeight, () => {
      if (!syncHeight.value) unobserve();
      else if (syncHeight.value) observe();
    });

    return {
      boxRef,
      boxHeight,
      cssVars,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.box {
  @apply rounded-big;
  @apply text-blue-teal;
  @apply text-sssm;

  line-height: px(22);
  overflow: hidden;
  width: var(--box-height, initial);

  @screen md {
    @apply text-sm;
  }

  &.small-border {
    @apply rounded-small;
  }

  &.padding {
    @include padding(px(17) px(24), px(59) px(55))
  }

  &.background {
    @apply bg-secondary;
  }

  &.force-aspect-ratio {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(100% * var(--aspect, 1));
      width: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
