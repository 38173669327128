<template>
  <Go
    class="btn arrow"
    :class="{ inverted }"
    :to="to"
    :disabled="disabled"
  >
    <span class="content">
      <slot />
    </span>
    <arrow class="arrow" />
  </Go>
</template>

<script>
import Arrow from '@/assets/images/arrow.svg';

export default {
  components: { Arrow },
  props: {
    to: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    arrow: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.btn {
  @apply bg-secondary;
  @apply rounded-full;
  @apply text-blue-teal;

  position: relative;
  padding: px(8) px(16);
  cursor: pointer;

  transition:
    background-color var(--speed-fast) ease,
    color var(--speed-fast) ease;

  &.inverted {
    @apply border;
    @apply text-gray;
    border-color: theme("colors.gray.DEFAULT");

    @include responsive('line-height', px(16), px(24));
    @include responsive('font-size', px(12), px(18));
    @include responsive('padding', px(10) px(13), px(17) px(35));

    background: none;
  }

  .arrow {
    position: absolute;
    height: 1em;
    top: 50%;
    transform: translateY(-50%);
    right: px(20);
    opacity: 0;
    will-change: opacity, right;
    transition: opacity var(--speed-fast) ease;

    :deep(path) {
      stroke: currentColor;
    }
  }

  .content {
    position: relative;
    left: 0;
    will-change: left;
    transition: left var(--speed-fast) ease;
  }

  &:hover {
    .arrow {
      opacity: 1;
    }

    .content {
      left: px(-15);
    }
  }
}
</style>
