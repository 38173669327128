import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
// import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from 'vue-gtag';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Arrow from '@/components/utils/Arrow.vue';
import Image from '@/components/utils/Image.vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import Accordion from '@/components/utils/Accordion.vue';
import LpgmbhButton from '@/components/utils/LpgmbhButton.vue';
import Slider from '@/components/utils/Slider.vue';
import Box from '@/components/utils/Box.vue';
import ProgressBar from '@/components/utils/ProgressBar.vue';
import Square from '@/components/utils/Square.vue';
import PageProgress from '@/components/utils/PageProgress.vue';
import IconButton from '@/components/utils/IconButton.vue';

import VueToast from 'vue-toast-notification';
import registerTextSplit from './utils/textSplit';

import store from './store';
import checkView from './vue-check-view';

import { cleanSvgDirective } from './utils/svg';

import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/styles/main.scss';

// polyfills
// --------------------------------

smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(VueToast, { position: 'top-right' });

if (!window.prerender) {
  app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
  // app.use(createGtm({ id: 'GTM-MMZ72P7' }));
  app.use(VueGtag, {
    config: { id: 'G-8KKTFJ1QFH' },
  });
} else {
  // Stub v-view and v-scroll-trigger
  app.directive('view', {});
  app.directive('scroll-trigger', {});
}

app.directive('no-anim', {
  beforeMount() {
    if (!window.prerender) window.document.body.classList.remove('no-anim');
  },
});

app.directive('clean-svgs', cleanSvgDirective);

registerTextSplit(app);

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');
app.config.globalProperties.$tryCDN = (url, width) => {
  if (url && url.includes('backend.jkweb.dev')) {
    const el = document.createElement('a');
    el.href = url;
    return `https://jkweb-dev.b-cdn.net${el.pathname}${width ? `?width=${width}` : ''}`;
  }
  return url;
};

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);
app.component('Arrow', Arrow);
app.component('Accordion', Accordion);
app.component('Dropdown', Dropdown);
app.component('LpgmbhButton', LpgmbhButton);
app.component('Slider', Slider);
app.component('Box', Box);
app.component('ProgressBar', ProgressBar);
app.component('PageProgress', PageProgress);
app.component('IconButton', IconButton);
app.component('Square', Square);

app.mount('#app');
