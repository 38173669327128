<template>
  <div class="constrain logo">
    <Go :to="'/' + $store.state.router.locale" aria-label="Home">
      <Logo class="logo" />
    </Go>
  </div>

  <div id="nav">
    <div class="relative constrain flex items-center text-sm justify-end">
      <nav class="inner flex">
        <transition-group name="default">
          <Go
            v-for="link of navigation?.links"
            :key="link.id"
            :to="link.uri"
            class="mx-12 lg:mx-16 link"
            :class="{ active: currentUri?.startsWith(link.uri) }"
            v-text="link.title"
          />
          <lpgmbh-button
            :key="contactLink?.id || 100"
            :to="contactLink?.uri"
            class="ml-12 lg:ml-16 link contact-btn"
            :class="{ active: currentUri?.startsWith(contactLink?.uri) }"
          >
            {{ contactLink?.linkText }}
          </lpgmbh-button>
        </transition-group>
      </nav>
    </div>
  </div>

  <Hamburger
    class="md:hidden"
    :class="{ active: mobile }"
    @click="toggle()"
  />

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition name="appear">
      <div v-if="mobile" class="mobile-nav">
        <div class="mobile-links">
          <accordion :items="mobileLinks" class="link-line">
            <template #header="{ expanded, item: link }">
              <Go
                :key="link.id"
                :disabled="hasChildren(link)"
                :to="link.uri"
                class="px-4 link"
                :class="{ active: currentUri?.startsWith(link.uri) }"
                @click="!hasChildren(link) && toggle(true)"
              >
                <div class="head" :class="{ 'has-children': hasChildren(link), expanded }">
                  <div
                    class="px-4 link"
                    :class="{ active: currentUri?.startsWith(link.uri) || expanded }"
                    v-text="link.title"
                  />
                  <arrow class="ml-auto flex-none down" :class="{ active: expanded }" />
                </div>
              </Go>
            </template>
            <template #default="{ item: link }">
              <div v-if="hasChildren(link)" class="children">
                <Go
                  v-for="sub of link.subs"
                  :key="sub.id"
                  :to="sub.uri"
                  class="px-4 link"
                  :class="{ active: currentUri?.startsWith(sub.uri) }"
                  @click="toggle(true)"
                  v-text="sub.title"
                />
              </div>
            </template>
          </accordion>
        </div>
        <div class="constrain button-line flex">
          <lpgmbh-button
            :to="contactLink?.uri"
            class="contact-btn"
            :class="{ active: contactLink?.uri === currentUri }"
            :arrow="true"
            v-text="contactLink?.linkText"
          />
          <lpgmbh-button
            :to="contact?.linkzuinstagram"
            class="btn social ml-auto"
            :class="{ active: contact?.uri === currentUri }"
          >
            <instagram />
          </lpgmbh-button>
          <lpgmbh-button
            :to="contact?.linkzulinkedin"
            class="btn social"
            :class="{ active: contact?.uri === currentUri }"
          >
            <linked-in />
          </lpgmbh-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Logo from '@/assets/images/logo.svg';

import Scrolllock from '@/composables/scrolllock';
import Instagram from '@/assets/images/instagram.svg';
import LinkedIn from '@/assets/images/linkedin.svg';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';
import LpgmbhButton from '../utils/LpgmbhButton.vue';

export default {
  components: {
    Logo,
    Language,
    Hamburger,
    Instagram,
    LinkedIn,
    LpgmbhButton,
  },
  setup() {
    const { lock, unlock } = Scrolllock();

    return {
      lock,
      unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    currentUri() {
      return this.$store.state.router.page.meta?.uri;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    contact() {
      return this.$store.state.router.layout.contact;
    },
    contactLink() {
      return this.$store.state.router.layout.contactLink;
    },
    mobileLinks() {
      return this.navigation?.links.map((link) => ({
        ...link,
        disabled: !link.subs.length,
      }));
    },
  },
  methods: {
    hasChildren(link) {
      return link.subs.length > 0;
    },
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.logo {
  position: relative;
  width: 100%;
  a {
    display: flex;
    flex-direction: column;
  }
  .logo {
    position: fixed;
    z-index: 1000;
    top: var(--header-padding);
    width: auto;
    height: calc(var(--header-height) - var(--header-padding) * 2);
  }
}

#nav {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 100;
  width: 100%;
  height: var(--header-height);
  pointer-events: none;
  box-shadow: 2px 2px var(--color-secondary);
  background-color: theme('colors.white');

  & > :first-child {
    margin: auto;
  }

  @media (max-width: theme("screens.md")) {

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.hamburger {
  position: fixed;
  z-index: 102;
  right: 0;
  top: calc(var(--header-height) / 2);
  transform: translateY(-50%);
  margin-right: px(30);
}

.inner,
.mobile-nav,
.hamburger {
  pointer-events: all;
}

.link {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  #nav &.contact-btn {
    @apply bg-secondary;
    @apply text-blue;
    @apply border;
    border-color: transparent;
    padding: px(16) px(36);
    border-radius: px(50);
    transition:
      background var(--speed-fast) ease,
      border-color var(--speed-fast) ease,
      color var(--speed-fast) ease;

    &:hover {
      @apply text-blue-teal;
      background: none;
      border-color: theme("colors.gray.DEFAULT");
    }
  }

  &.active {
    @apply text-primary;

    &::after {
      @apply bg-primary;

      content: "";
      position: absolute;
      left: 0;
      top: calc(100% + #{px(6)});
      width: 100%;
      height: 2px;
    }
  }

  #nav &.active:last-child::after {
    content: none;
  }
}

.header > .link.active::after {
  content: none;
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 101;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: theme('colors.blue.teal');
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 101;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;

  @apply text-white;

  .link {
    margin: px(16) 0;
    padding: 0;
    text-decoration: none !important;
  }

  .mobile-links {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 60%;
    padding-top: px(62);
    padding-bottom: px(62);
  }
}

.link-line .head {
   @apply flex;
   @apply justify-between;
   @apply items-center;
   @apply text-white;
}

:deep(.accordion.expanded) .head {
  color: rgba(255, 255, 255, 0.6);
}

.link-line .children {
  @apply flex;
  @apply flex-col;
  @apply items-start;
  padding-top: px(3);
  padding-bottom: px(12);

  .link {
    margin-top: px(8);
    margin-bottom: px(8);
    line-height: px(22);
  }
}
:deep(.dropdown):not(:last-child) {
  @apply border-b;
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

:deep(.arrow) {
  .has-children & {
    &.down {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(-90deg);
    }
  }
}

.button-line {
  padding-bottom: px(33);
}

.btn.social {
  width: px(16);
  height: px(16);
  padding: px(10);
  box-sizing: content-box;

  &:last-child {
    margin-left: px(6);
  }
}
</style>
