<template>
  <div class="progress" :style="styles" />
</template>

<script>
import { computed } from 'vue';
import { cssVars } from '@/utils/css';

export default {
  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1 },
    start: { type: Number, default: 0 },
    end: { type: Number, default: 0.5 },
    width: { type: String, default: '3px' },
  },
  setup(props) {
    const start = computed(() => Math.max(props.start, props.min));
    const end = computed(() => Math.min(props.end, props.max));
    const percentage = computed(() => ((end.value - start.value) / (props.max - props.min)) * 100);
    const styles = computed(() => cssVars({
      offset: `${(start.value / props.max) * 100}%`,
      width: props.width,
      percentage: `${percentage.value}%`,
    }));
    return {
      styles,
    };
  },
};
</script>

<style lang="scss" scoped>
.progress {
  @apply bg-orange-lite;
  @apply rounded-full;

  position: relative;
  width: 100%;
  height: var(--width);

  --distance: var(--max) - var(--min);

  &::after {
    @apply bg-orange;
    @apply rounded-full;

    content: "";
    position: absolute;
    top: 0;
    left: var(--offset);
    width: var(--percentage);
    height: var(--width);
    transition:
      left var(--speed-fast) ease,
      width var(--speed-fast) ease;
  }
}
</style>
