<template>
  <div class="accordion" :class="{ expanded: expandedItems.size > 0 }">
    <dropdown
      v-for="item in items"
      :key="item.id"
      :expanded="isExpanded(item)"
      :disabled="item.disabled"
      @update:expanded="setExpanded(item, $event)"
    >
      <template #header="{ expanded }">
        <slot
          :key="item.id"
          name="header"
          :item="item"
          :expanded="expanded"
        />
      </template>
      <template #default="{ expanded }">
        <slot
          :key="item.id"
          :item="item"
          :expanded="expanded"
        />
      </template>
    </dropdown>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { useOptionalModel } from '@/composables/model';

export default {
  props: {
    mode: { type: String, default: 'single' },
    items: { type: Array, default: () => [] },
    modelValue: { type: Array, default: () => [] },
  },
  setup(props, context) {
    const propsAsRefs = toRefs(props);
    const expanded = useOptionalModel(propsAsRefs, context.emit);

    const isExpanded = (item) => !!expanded.value.includes(item);

    const setExpanded = (item, value) => {
      if (isExpanded(item)) {
        expanded.value = expanded.value.filter(({ id }) => item.id !== id);
      } else if (value && propsAsRefs.mode.value === 'single') {
        expanded.value = [item];
      } else {
        expanded.value = [...expanded.value, item];
      }
    };

    return {
      expandedItems: expanded,
      setExpanded,
      isExpanded,
    };
  },
};
</script>

<style>

</style>
