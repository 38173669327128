<template>
  <div class="square">
    <div class="spacer">
      <slot />
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.square {
  position: relative;
  overflow: hidden;
  height: 100%;

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .spacer {
    position: relative;
    display: block;
    padding-bottom: 100%;
    visibility: hidden;
    width: fit-content;
    height: 0;

    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      width: 100%;
      height: 0;
    }
  }
}
</style>
