<template>
  <footer class="footer text-sm">
    <div class="constrain footer-main">
      <div class="row row-12">

        <div class="col-2">
          <logo class="logo" />
        </div>
        <div class="col-3 flex flex-col links">
          <Go
            v-for="link of payload?.hauptreiter"
            :key="link.id"
            :to="link.link"
            class="link"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
        </div>

        <div class="col-4 links leistungen">
          <Go
            v-for="link of payload?.leistungen"
            :key="link.id"
            :to="anchorize(link.link)"
            class="link"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
        </div>

        <div class="col-2 offset-1">
          <p class="text-sm">
            {{ payload.social }}
          </p>
          <p class="text-sm">
            <Go :to="payload.contact?.linkzuinstagram" class="inline-block social">
              <instagram />
            </Go>
            <Go :to="payload.contact?.linkzulinkedin" class="inline-block social">
              <linked-in />
            </Go>
          </p>
        </div>

      </div>
    </div>
    <div class="footer-line">
      <div class="constrain flex flex-wrap justify-between">
        <span class="text-xs order-2 sm:order-1">© 2022 L&P Dämmtechnik + Verputze GmbH</span>
        <div class="meta-links text-xs order-1 sm:order-2">
          <Go :to="payload.impressum?.link" class="text-xs">
            {{ payload.impressum?.title }}
          </Go>
          <Go :to="payload.datenschutz?.link" class="text-xs ml-3">
            {{ payload.datenschutz?.title }}
          </Go>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { anchorize } from '@/utils/anchor';
import Logo from '@/assets/images/logo.svg';
import Instagram from '@/assets/images/instagram.svg';
import LinkedIn from '@/assets/images/linkedin.svg';
import jkButton from '../utils/jkButton.vue';

export default {
  components: {
    Logo,
    Instagram,
    LinkedIn,
    jkButton,
  },
  setup() {
    const store = useStore();
    return {
      anchorize,
      payload: computed(() => store.state.router.layout.footer || {}),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.footer {
  box-shadow: 2px -1px var(--color-secondary);
  margin-top: auto;
  background: white;
}
.footer-main {
  display: none;
  padding-top: px(52);
  padding-bottom: px(69);

  @screen md {
    display: block;
  }
}

.footer-line {
  @apply bg-secondary;
  @apply text-blue;

  padding-top: px(18);
  padding-bottom: px(18);
}

.credit {
  color: #B29B3A;
}

.links {
  margin: px(math.div(-17, 2)) 0;

  .link {
    display: block;
    padding: #{math.div(px(17), 2)} 0;
  }
}

.credit-link:not(:last-child)::after {
  content: ', ';
  color: #B29B3A;
}

.leistungen {
  color: theme('colors.gray.DEFAULT');
  columns: 2;
}

.logo {
  width: px(60);
  height: px(60);
}

.social {
  padding: px(9);
  @apply mr-2;
  @apply bg-secondary;
  @apply rounded-full;

  svg {
    width: px(16);
    height: px(16);
  }
}

</style>
